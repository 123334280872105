<template>
  <header>
    <div class="header_top">
      <div class="left-block">
        <!-- <a href="tel:+380970530842"
          ><img src="@/assets/phone-black.svg" alt="" />
          <div class="text">0970530842</div></a
        >
        <a href="#"
          ><img src="@/assets/mail-black.svg" alt="" />
          <div class="text">pherchyk@gmail.com</div></a
        > -->
      </div>
      <div class="right-block">
        <a href="https://t.me/feshchuk_ps"
          ><img src="@/assets/telegram-black.svg" alt=""
        /></a>
        <a href="https://www.facebook.com/pherchyk"
          ><img src="@/assets/facebook-black.svg" alt=""
        /></a>
        <a href="https://www.instagram.com/feshchuk.ps/"
          ><img src="@/assets/instagram-black.svg" alt=""
        /></a>
      </div>
    </div>

    <div class="header_menu">
      
      <ul>
        <li class="text" @click="scrollToSection('section1')">Про Мене</li>
        <li class="text" @click="scrollToSection('section2')">
          Що ви отримаєте
        </li>
        <li class="text" @click="scrollToSection('section3')">
          моя професійна кваліфікація
        </li>
        <li class="text" @click="scrollToSection('section4')">Напрями діяльності</li>
        <!-- <li class="text">Вартість</li>
        <li class="text">Контакти</li> -->
      </ul>
    </div>
  </header>
  <div class="about" id="section1">
    <div class="about_background"></div>
    <div class="about_container">
      <div class="about_photo">
        <img style="width: 800px" alt="feshchuk" src="@/assets/tanya.jpg" />
      </div>
      <div class="about_text">
        <h3 class="text">ПСИХОЛОГ</h3>
        <h1 class="text">Фещук Тетяна</h1>
        <div class="text">
         <p> Пізнай себе та знайди внутрішню рівновагу</p>
         <p> Привіт! Я – Тетяна Фещук, практичний психолог, що допомагає знаходити гармонію, упевненість і внутрішню стійкість.</p>

         <p> У своїй роботі я поєдную науковий підхід із сучасними методами психології, щоб підтримати тебе у складних періодах життя, допомогти краще розуміти себе та вибудовувати здорові стосунки з іншими.</p>

         <p> 🚀 Чим можу допомогти?</p>

         <p> · Подолання тривожності та стресу</p>
         <p> · Робота з самооцінкою та впевненістю</p>
          <p> · Покращення емоційного стану</p>
          <p> · Вирішення труднощів у стосунках</p>
          <p> · Підтримка у важливих життєвих змінах</p>
          <p> · Консультації – це простір без оцінок і критики, де ти можеш бути собою.</p>
          <p> · Готова підтримати тебе на шляху до психологічного комфорту.</p>
         </div>
      </div>
    </div>
  </div>

  <div class="info sign_up">
    <h2 class="text">Зміни своє життя прямо зараз!</h2>
    <h3 class="text">Запишись на першу консультацію до психолога</h3>
    <a class="button text" href="https://t.me/feshchuk_ps">Запис</a>
    <a class="button text" href="https://t.me/feshchuk_ps">
      Написати в телеграм
    </a>
  </div>

  <div class="you_get" id="section2">
    <div class="you_get_background"></div>
    <h2 class="text">Що ви отримаєте?</h2>
    <div class="you_get_blocks">
      <div class="you_get_item">
        <img class="big_ico" src="@/assets/understand.svg" alt="" />
        <h3 class="text">Поглиблене розуміння себе</h3>
        <p class="text">
          Ми розглянемо ваші думки, емоції та переживання для кращого розуміння
          ваших потреб і бажань.
        </p>
      </div>
      <div class="you_get_item">
        <img class="big_ico" src="@/assets/strategy.svg" alt="" />
        <h3 class="text">Розробка практичних стратегій</h3>
        <p class="text">
          Разом ми створимо індивідуальний план дій, який допоможе вам подолати
          виклики та здобути більше впевненості.
        </p>
      </div>
      <div class="you_get_item">
        <img class="big_ico" src="@/assets/empathy.svg" alt="" />
        <h3 class="text">Підтримка і емпатія</h3>
        <p class="text">
          Ви зможете відкрито спілкуватися, відчуваючи, що вас розуміють та
          підтримують.
        </p>
      </div>
      <div class="you_get_item">
        <img class="big_ico" src="@/assets/privacy.svg" alt="" />
        <h3 class="text">Конфіденційність</h3>
        <p class="text">
          Всі ваші розмови зі мною залишаться абсолютно конфіденційними.
        </p>
      </div>
    </div>
  </div>

  <div class="achievements" id="section3">
    <h2 class="text">Магістерська програма</h2>
    <div class="achievements_text">
      <p class="text">
      <span>   Я здобула ступінь магістра практичної психології у Маріупольському державному університеті з відзнакою.</span> 

      <span>Додаткова спеціалізація</span>     
        <span>Пройшла спеціалізацію з клінічної психології в Інституті післядипломної освіти КНУ імені Тараса Шевченка, що дозволяє мені працювати з глибшими та складнішими психологічними запитами.</span>     

          <span>Навчання терапевтичному методу</span>     
            <span>Зараз навчаюся терапевтичному методу логотерапії Віктора Франкла — науково-доказовому підходу, що допомагає людям переосмислювати життєві труднощі та знаходити сенс навіть у складних обставинах.</span>     

              <span>Професійний розвиток</span>     
<span>Є членом Національної психологічної асоціації, що забезпечує доступ до актуальних професійних стандартів, навчальних програм і супервізійної підтримки.
Проходжу власну терапію, що дозволяє мені глибше розуміти процеси, з якими стикаються клієнти. Регулярно беру участь у супервізіях та інтервізійних групах, що допомагає покращувати якість роботи та отримувати професійну підтримку. 
Відвідую тренінги, семінари та вебінари, щоб працювати відповідно до сучасних тенденцій психології та застосовувати ефективні методи у своїй практиці. Моя мета — надавати якісну психологічну підтримку, спираючись на науково обґрунтовані підходи, безперервний професійний розвиток та етичні стандарти роботи.</span>
      </p>
      <div class="slider">
        <CaruselItem />
      </div>
    </div>
  </div>
  
  <div class="info question">
    <h2 class="text">У вас є питання ?</h2>
    <h3 class="text">Поставте запитання спеціалісту</h3>
    <a class="button text" href="https://t.me/feshchuk_ps"
      >Поставити запитання</a
    >
    <a class="button text" href="https://t.me/feshchuk_ps">Залишити заявку</a>
  </div>

  <div class="education" id="section4">
    <div class="education_background"></div>
    <!-- <div class="education_block">
      <h3 class="text">Освіта та професійний розвиток</h3>
      <p class="text">
        <span> Магістерська програма</span>
          <span>Я здобула ступінь магістра практичної психології у Маріупольському державному університеті з відзнакою.</span>

            <span>Додаткова спеціалізація
        Пройшла спеціалізацію з клінічної психології в Інституті післядипломної освіти КНУ імені Тараса Шевченка, що дозволяє мені працювати з глибшими та складнішими психологічними запитами.</span>

        <span>Навчання терапевтичному методу
        Зараз навчаюся терапевтичному методу логотерапії Віктора Франкла — науково-доказовому підходу, що допомагає людям переосмислювати життєві труднощі та знаходити сенс навіть у складних обставинах.</span>

        <span>Професійний розвиток</span>

        <span>Є членом Національної психологічної асоціації, що забезпечує доступ до актуальних професійних стандартів, навчальних програм і супервізійної підтримки.
        Проходжу власну терапію, що дозволяє мені глибше розуміти процеси, з якими стикаються клієнти.
        Регулярно беру участь у супервізіях та інтервізійних групах, що допомагає покращувати якість роботи та отримувати професійну підтримку.
        Відвідую тренінги, семінари та вебінари, щоб працювати відповідно до сучасних тенденцій психології та застосовувати ефективні методи у своїй практиці.
        Моя мета — надавати якісну психологічну підтримку, спираючись на науково обґрунтовані підходи, безперервний професійний розвиток та етичні стандарти роботи.</span>
      </p>
    </div> -->
    <div class="education_block">
      <h3 class="text">Напрями Діяльності</h3>
      <p class="text">
        <span> Я створюю безпечний простір, де можна відкрито говорити про складні переживання, отримати підтримку без осуду та знайти власні ресурси для змін.</span>

        <span> Самооцінка та самоцінність   </span>

        <span> Відновлення відчуття власної цінності
        Формування здорового ставлення до себе
        Подолання самокритики та сумнівів</span>

        <span>Пошук сенсу та життєвого шляху
        Усвідомлення власних цінностей та потреб
        Робота з екзистенційними кризами</span>

        <span>Пошук мотивації та внутрішніх опор</span>

        <span>Особисті та міжособистісні відносини
        Взаємодія у партнерських стосунках
        Побудова здорових меж
        Поліпшення комунікативних навичок</span>

        <span>Підтримка в умовах війни
        Робота з тривожністю та хронічним стресом
        Відновлення внутрішнього балансу в нестабільних обставинах</span>

        <span>Порушення харчової поведінки</span>

        <span>Робота з емоційним прийомом їжі
        Формування усвідомленого ставлення до тіла та харчування
        Підтримка без тиску та нав’язливих рішень</span>

        <span>Розлад дефіциту уваги (ADHD) у дорослих</span>

        <span>Організація повсякденного життя
        Робота з концентрацією та імпульсивністю
        Розвиток навичок саморегуляції</span>

        <span>Я працюю з науково обґрунтованими методами та створюю простір прийняття, де ви можете бути собою та отримати професійну підтримку на шляху до змін.</span>

        <span>У цьому розділі ви також можете ознайомитися з моїми дипломами, сертифікатами, документами про спеціалізацію та членство у професійних спільнотах, що підтверджують мою кваліфікацію та прагнення до професійного розвитку.
        </span>
      </p>
    </div>
  </div>
  <footer>
    <div class="footer">
      <div class="left-block">
        <!-- <a href="tel:+380970530842"
          ><img src="@/assets/phone-white.svg" alt="" />
          <div class="text">0970530842</div></a
        >
        <a href="#"
          ><img src="@/assets/mail-white.svg" alt="" />
          <div class="text">pherchyk@gmail.com</div></a
        > -->
      </div>
      <div class="right-block">
        <a href="https://t.me/feshchuk_ps"
          ><img src="@/assets/telegram-white.svg" alt=""
        /></a>
        <a href="https://www.facebook.com/pherchyk"
          ><img src="@/assets/facebook-white.svg" alt=""
        /></a>
        <a href="https://www.instagram.com/feshchuk.ps/"
          ><img src="@/assets/instagram-white.svg" alt=""
        /></a>
      </div>
    </div>
  </footer>
  <FeedbackModal v-if="showModal" @close="showModal = false" />
</template>

<script>
import FeedbackModal from "@/components/FeedbackModal.vue";
import CaruselItem from "@/components/CaruselItem.vue";
export default {
  name: "App",
  data() {
    return {
      showModal: false,
    };
  },
  components: {
    FeedbackModal,
    CaruselItem,
  },
  methods: {
    scrollToSection(sectionId) {
      this.$scrollTo(document.querySelector(`#${sectionId}`), 500);
    },
  },
};
</script>

<style lang="scss">
$gray: #000000aa;
$black: #000000;
$white: #ffffff;
$blue: #eeeeee;
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
  color: $black;
  font-weight: 600;
  font-size: 16px;
}
body {
  margin: 0;
  background: $white;
  a {
    color: $black;
    text-decoration: none;
  }
  .text {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $black;
    text-decoration: none;
    font-weight: 600;
  }
  header {
    position: fixed;
    width: 100%;
    opacity: 0.98;
    top: 0;
    z-index: 2;
  }
  .header_top {
    display: flex;
    justify-content: space-between;
    padding: 15px 100px;
    margin: 0;
    align-items: center;
    background: $white;
    .left-block {
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        margin-right: 20px;
        img {
          margin-right: 15px;
        }
      }
    }
    .right-block {
      display: flex;
      a {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    }
  }
  .header_menu {
    background: $gray;
    padding: 0 100px;
    ul {
      display: flex;
      padding: 0;
      margin: 0;
      .text {
        color: $white;
        text-transform: uppercase;
        text-decoration: none;
        list-style-type: none;
        letter-spacing: 1.5px;
      }
      li {
        margin-right: 40px;
        padding: 30px 0;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
  .about {
    display: flex;
    height: calc(100vh - 64px);
    margin-top: 64px;
    position: relative;
    .about_background {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("@/assets/office.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0.175;
    }
    .about_container {
      display: flex;
      align-items: center;
      .about_photo {
        padding: 15px 100px;
        img {
          border-radius: 50%;
          margin: 50px 0;
        }
      }
      .about_text {
        padding: 15px 100px;
        background: rgba(255, 255, 255, 0.1);
        .text {
          text-align: left;
        }
        h3 {
          font-size: 24px;
          font-weight: 700;
          margin: 0;
        }
        h1 {
          font-size: 72px;
          font-weight: 900;
          margin: 0;
        }
        p {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }
  .button {
    border: 2px solid $white;
    border-radius: 40px;
    display: inline-block;
    padding: 10px 40px;
    margin: 5px;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 20px;
    background-color: rgb(51,144,236);
    
  }
  .info .button.text{
    color:white;
  }
  .info {
    background-color: $blue;
    padding: 50px 100px;
    text-align: center;
    .text {
      color: $black;
    }
    h2 {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .you_get {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .you_get_background {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("@/assets/people.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0.7;
    }
    h2 {
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 24px;
      text-align: left;
      padding: 50px 100px;
    }
    .you_get_blocks {
      display: flex;
      padding: 50px 50px;
      .you_get_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 25px;
        flex: 1;
        flex-basis: 0;
        margin-bottom: 100px;
        .big_ico {
          width: 128px;
          margin-bottom: 10px;
        }
        h3.text {
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 20px;
        }
        .text {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          letter-spacing: 1.5px;
        }
      }
    }
  }
  .achievements {
    background-color: $black;
    padding-top: 84px;
    h2 {
      color: $white;
      text-align: left;
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 24px;
      padding: 50px 100px;
    }
    .achievements_text {
      display: flex;
      .text {
        color: $white;
        span {
          padding: 0px 100px;
          margin-top: 20px;
          display: block;
          text-align: left;
          font-size: 18px;
          line-height: 30px;
          font-weight: 500;
        }
      }
    }
    .slider {
      max-width: 800px;
      height: 800px;
      background-color: #222222;
    }
  }
  .education {
    min-height: 100vh;
    position: relative;
    padding: 50px 100px;
    display: flex;
    .education_background {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("@/assets/books.jpg");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .education_block {
      padding: 50px;
      border-radius: 50px;
      background: rgba(255, 255, 255, 0.9);
      flex: 1;
      flex-basis: 0;
      margin: 50px;
      .text {
        margin-top: 20px;
        text-align: left;
        font-size: 18px;
        line-height: 30px;
        font-weight: 500;
        span {
          display: block;
          margin-top: 20px;
        }
      }
      h3.text {
        font-size: 48px;
        line-height: 64px;
        font-weight: 700;
        margin-bottom: 40px;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 15px 100px;
    margin: 0;
    align-items: center;
    background-color: $blue;
    .left-block {
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .text {
          color: $black;
        }
        img {
          margin-right: 15px;
        }
      }
    }
    .right-block {
      display: flex;
      color: $black;
      a {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    }
  }
}
@media (max-width: 1600px) {
  body {
    .about {
      .about_background {
      }
      .about_container {
        .about_photo {
          img {
            max-width: 480px;
          }
        }
        .about_text {
          h1 {
            font-size: 64px;
          }
          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media (max-width: 1367px) {
  body {
    .about {
      .about_background {
      }
      .about_container {
        .about_photo {
          max-width: 280px;
          img {
            max-width: 380px;
          }
        }
        .about_text {
          h1 {
            font-size: 48px;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
    .you_get {
      height: auto;
      .you_get_blocks {
        flex-wrap: wrap;
        .you_get_item {
          min-width: 300px;
        }
      }
    }
    .achievements {
      .achievements_text {
        flex-direction: column;
        .slider {
          margin-top: 40px;
          min-width: 100%;
        }
      }
    }
    .education {
      padding: 50px 100px;
      .education_block {
        padding: 30px;
        margin: 10px;
        h3.text {
          font-size: 26px;
          line-height: 32px;
          font-weight: 700;
          margin-bottom: 40px;
        }
      }
    }
  }
}
@media (max-width: 1050px) {
  body {
    .header_menu {
      padding: 0 20px;
    }
    .about {
      margin-top: 140px;
      height: auto;
      .about_container {
        flex-direction: column;
        .about_photo {
          max-width: 100%;
          max-width: 360px;
          img {
            max-width: 100%;
          }
        }
        .about_text {
          h1 {
            font-size: 64px;
          }
          p {
            font-size: 20px;
          }
          margin-bottom: 40px;
        }
      }
    }
    .you_get {
      .you_get_blocks {
        flex-direction: column;
      }
    }
    .education {
      padding: 50px 100px;
      flex-direction: column;
      .education_block {
        padding: 30px;
        margin: 10px;
        h3.text {
          font-size: 26px;
          line-height: 32px;
          font-weight: 700;
          margin-bottom: 40px;
        }
      }
    }
  }
}
@media (max-width: 750px) {
  body {
    .header_top {
      padding: 15px 20px;
      .left-block {
        flex-direction: column;
        align-items: flex-start;
        a {
          margin-right: 0px;
          margin: 10px;
        }
      }
    }
    .header_menu {
      display: none;
    }
    .about {
      .about_container {
        .about_photo {
          padding: 0px 0px;
          max-width: 300px;
        }
        .about_text {
          padding: 15px 20px;
        }
      }
    }
    .info {
      padding: 50px 20px;
    }
    .you_get {
      h2 {
        padding: 50px 20px;
      }
      .you_get_blocks {
        padding: 50px 20px;
      }
    }
    .education {
      padding: 50px 20px;
      flex-direction: column;
      .education_block {
        padding: 30px;
        margin: 10px;
        h3.text {
          font-size: 26px;
          line-height: 32px;
          font-weight: 700;
          margin-bottom: 40px;
        }
      }
    }
    .achievements {
      h2 {
        padding: 50px 20px;
        font-size: 24px;
      }
      .achievements_text {
        .text {
         // color: $black;
          span {
            padding: 0px 20px;
          }
        }
      }
    }
    .education {
      padding: 50px 20px;
      .education_block {
        padding: 20px;
        margin: 20px;
      }
    }
    .footer {
      padding: 15px 20px;
      .left-block {
        flex-direction: column;
        align-items: flex-start;
        a {
          margin-right: 0px;
          margin: 10px;
        }
      }
    }
  }
}
@media (max-width: 450px) {
  body {
    .header_top {
      padding: 20px 0px;
      .left-block {
        img {
          width: 24px;
        }
      }
      .right-block {
        img {
          width: 24px;
        }
        //flex-direction: column;
        // align-items: flex-start;
        a {
          margin-right: 0px;
          margin: 10px;
        }
      }
    }
    .you_get {
      h2 {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 24px;
        text-align: left;
        padding: 40px 20px;
      }
      .you_get_blocks {
        padding: 15px 15px;
       
        .you_get_item {
          margin-bottom: 35px;
          max-width: 260px;
          margin:20px 0;
          h3.text {
          font-size: 24px;

          }
        }

    

      }
    }
    .about {
      .about_container {
        .about_photo {
          img {
            max-width: 200px;
            margin: 10px 0;
          }
        }
        .about_text {
          h1 {
            font-size: 32px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
    .education {
      padding: 25px 0px;
    }
    .footer {
      padding: 20px 0px;
      .left-block {
        flex-direction: column;
        align-items: flex-start;
        a {
          margin-right: 0px;
          margin: 10px;
        }
        img {
          width: 24px;
        }
      }
      .right-block {
        img {
          width: 24px;
        }
      }
    }
  }
}
</style>
